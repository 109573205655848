.auth-wrapper {
  min-height: 100vh;
  position: relative;
  .auth-box {
    background: $white;
    padding: 20px;
    box-shadow: $shadow;
    max-width: 400px;
    width: 90%;
    margin: 10% 0;
    .logo {
      text-align: center;
    }
    &.on-sidebar {
      top: 0px;
      right: 0px;
      height: 100%;
      margin: 0px;
      position: absolute;
    }
  }
  #recoverform {
    display: none;
  }
  .auth-sidebar {
    position: fixed;
    height: 100%;
    right: 0px;
    overflow: auto;
    margin: 0px;
    top: 0px;
  }
}

.error {
  color: $danger;
}
.d-bottom {
  bottom: 15px;
  position: absolute;
}
.font-login {
  font-size: 26px;
  font-weight: 300;
}
.font-login-small {
  font-size: 18px;
  font-weight: 300;
}
.forgot-pw {
    right: 45px;
    position: absolute;
}

@include media-breakpoint-up(md) {
  .reset-height {
    height: 572px;
  }
  .reset-text {
    bottom: -60px;
    position: relative;
  }
  .reset-form {
    margin-top: 35% !important;
    margin-bottom: 25% !important;
  }
  .reset-btn {
    position: relative;
    bottom: -140px;
  }
}
@include media-breakpoint-down(sm) {
  .auth-wrapper {
    .auth-sidebar {
      position: relative;
      max-width: 100%;
      width: 100%;
      margin: 40px 0 60px;
    }
    .demo-text {
      margin-top: 30px;
    }
  }
}
