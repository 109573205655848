.download-block {
  button {
    border: none;
    font-weight: bold;
    background: none;
    color: #51ae32;
  }

  button:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
