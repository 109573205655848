.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-size: none;
}

.phone-input {
  display: block;
  width: 100%;
  height: $input-height;

  input {
    display: block;
    width: 100% !important;
    height: $input-height !important;
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}
