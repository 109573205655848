@font-face {
    font-family: Diagri;
    src: url("../fonts/ITCAvantGardeStd-Bk.otf") format("opentype");
}

@font-face {
    font-family: Diagri;
    font-weight: 700;
    src: url("../fonts/ITCAvantGardeStd-Demi.otf") format("opentype");
}
@font-face {
    font-family: Diagri;
    font-weight: 600;
    src: url("../fonts/ITCAvantGardeStd-Md.otf") format("opentype");
}

