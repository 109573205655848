.modal-view-mode {
    .pending {
      color: grey;
    }
    .accepted {
      color: #51ae32;
    }
    .declined {
      color: red;
    }
  }
  