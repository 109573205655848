/*******************************/
// Bootstrap overrides
/*******************************/
@import 'variables.scss';
@import 'mixins/background-variant';
@import 'utilities/backgrounds';
@import 'utilities/floating';
@import 'text.scss';
@import 'card.scss';
@import 'type.scss';
@import 'badge.scss';
@import 'reboot.scss';
@import 'perfect-scrollbar.scss';
@import 'spinner.scss';
@import 'buttons.scss';
@import 'topbar-dd.scss';
@import 'chat-widget.scss';
@import 'comments.scss';
@import 'feeds.scss';
@import 'to-do-list.scss';
@import 'activity-timeline.scss';
@import 'navs.scss';
@import 'progress.scss';
@import 'globle.scss';
@import 'input-group.scss';


//Theme core files
@import 'core/theme-colors/theme-colors';
@import 'core/theme-colors/dark-theme';
@import 'core/layout/layout';
@import 'core/topbar/topbar';
@import 'core/sidebar/sidebar';
@import 'core/customizer/customizer';

// Theme pages
@import 'pages/authentication/authentication';
@import 'pages/inbox/emails';
@import 'pages/forms/typeahead';
@import 'pages/gallery-usercard/gal-card';
@import 'pages/timeline/timeline';
@import 'pages/calendar/calendar';
@import 'pages/dashboards/dashboard3';

// rtl style
@import 'rtl/rtl';

// Custom css
@import "_fonts.scss";

.seperator {
	border-bottom: 1px solid #e9ecef;
    padding-bottom: 20px;
	padding-top: 35px;
	margin-bottom: 35px;
}
.form-check-input {
    margin-top: 0.1rem;
}

.simpleList {
	margin: 0;
	padding: 0;
	margin-top: 30px;
	list-style-type: none;
	&.extra {
		li {
			margin-bottom: 10px;
			color: black;
		}
	}
}
.simpleListItem {
	padding: 15px 0px;
}
.breadcrumb {
	padding-left: 0px;
	padding-top: 0px;
}
// .breadcrumb-item {
// 	text-transform: uppercase;
// }
.pad-30 {
	padding: 30px;
	display: flex;
	.fr {
		flex-direction: row-reverse;
	}
}
.no-bg {
	background: none !important;
}

$red: #e73a1e;
$green: #51AE32;
$grey: #9d9d9d;
$darkGrey: #9d9d9d;
$blue: #51AE32;
$amber: orange;

.statusIndicator {
	width: 10px;
    height: 10px;
    float: left;
    border-radius: 50%;
    margin-right: 7px;
    top: 3px;
	position: relative;

	&.connected {
		background-color: $green;
	}
	&.green {
		background-color: $green;
	}

	&.amber {
		background-color: $amber;
	}

	&.errored {
		background-color: $red;
	}
	&.red {
		background-color: $red;
	}

	&.disconnected {
		background-color: $grey;
	}
	&.discharged {
		background-color: $darkGrey;
	}
}
button.outline {
	background-color: transparent;
	border: 1px solid $grey;
	color: $grey;
	margin-left: 10px;
	&:hover {
		background-color: transparent;
		color: $grey;
	}
	&.danger {
		color: $red;
		border-color: $red;
	}
	&.blue {
		border: 1px solid $blue;
		color: $blue;
		&:hover {
			background-color: transparent;
			color: $blue;
		}
		&.danger {
			color: $blue;
			border-color: $blue;
		}
	}
}
p.nobottom {
	margin-bottom: 0px !important;
}
a.delete-link {
	color: $red;
}
.register-text {
	padding: 90px 0px 90px 0px;
	text-align: center;
	font-size: 0.875rem;
	margin-bottom: 0;
}
.mapobject {
	height: 400px;
	z-index: 1;
	overflow: hidden;
}
#track_map {
	.leaflet-draw-edit-remove {
		display: none !important;
	}
}
.css-15k3avv {
	z-index: 2 !important;
}
.sr-only {
    display: none;
  }
.flex-reverse {
	display: flex;
	flex-direction: row-reverse;
	& > *{
		margin-left: 15px;
	}
}
.padb-30 {
	margin-bottom: 30px;

	.nb {
		padding-bottom: 0px;
	}
}
.table.no-border {
	&.no-border {
		td {
			border: none;
		}
	}
	&.width {
		td:first-child {
			width: 32%;
		}
	}
}
.no-border {
	border: none;
}
.ov {
	overflow: visible;
	z-index: 30;
}
.nav-tabs.fancy-tabs {
	margin: 10px;
	border: none;
	.nav-link {
		border: none;
		background-color: transparent;
		cursor: pointer;
		padding: 0.5em 1em;
		&.active {
			color: $blue;
			border-bottom: 4px solid $blue;
			font-weight: 600;
		}
	}
	&:after {
		border: 4px solid $grey;
		display: block;
	}
}
.text-red {
	color: $red;
}
.logo-text {
	padding-left: 5%;
}
.mapobject {
	&.farms {
		.leaflet-draw-edit-edit,
		.leaflet-draw-edit-remove,
		.leaflet-draw-toolbar {
			display: none;
		}
		&.editing {
			.leaflet-draw-edit-edit,
			.leaflet-draw-edit-remove,
			.leaflet-draw-toolbar {
				display: block;
			}
		}
	}
}
.animal-profile-pic {
	max-width: 100%;
}
.animal-box {
	margin: 5%;
	padding: 20px;
	border: 1px solid #e6e9ec;
}
.customizer {
	display: none;
}
.underline {
	text-decoration: underline;
}
tr > td:first-child > div.form-check {
	padding-left: 35px;
}
.tag-table {
	width: calc(100% - 60px);
	margin-left: 30px;
}
.farm-table tr td:first-child,
.farm-table tr th:first-child {
	padding-left: 35px;
}
table.less-pad {
	td {
		padding: 10px;
		&:first-child {
			padding-left: 0px;
			color: #1B1818;
			font-weight: 600;
		}
		color: $grey;
	}
}
thead {
	background-color: #f6f6f6;
}
.animal-photo {
	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.icon-logo {
	padding: 5%;
	width: 50px;
}
.mini-sidebar .icon-logo {
	display: block;
}
.icon-logo {
	display: none;
}
.dropdown-toggle {
	margin-right: 10px !important;
}
.ReactTable {
	.rt-th,
	.rt-td {
		padding: 15px !important;
		border-right: none !important;
		&:first-child {
			padding-left: 34px !important;
		}

		// @media (max-width: 768px) {
		// 	width: 300px !important;
		// }
	}
	.rt-tr.selected {
		background-color: rgba($blue, 0.3)
	}


	.ReactTable {
		margin-top: 74px;
	}

	.ReactTable .rt-tbody {
		margin-top: 30px;
	}

	.ReactTable .rt-thead {
		background-color: white;
		position: fixed;
		top: 1;
		z-index: 1;
		width: calc(100% - 17px);
		height: 31px;
	}

	// .rt-th {

	// 	&:before {
	// 		content: "▼\A▲";
	// 		float: right;
	// 		position: relative;
	// 		top: -2px;
	// 		white-space: pre;
	// 	}

	// }
	.-sort-desc,
	.-sort-asc,
	.rt-th {
		box-shadow: none !important;
		&:before {
			float: right;
			width: 5px;
			margin-left: 5px;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	  .rt-th {
			background-color: #f6f6f6;
			color: #9d9d9d;
			font-weight: 600;
			text-align: left;
			vertical-align: middle;
			&.-cursor-pointer {
				&:before {
					content: url("../images/no-sort.svg");
				}
			}
			&:before {
				content: " "
			}

		& > div {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.-sort-desc {
		&:before {
			content: url("../images/desc-sort.svg") !important;
		}
	}
	.-sort-asc {
		&:before {
		  	content: url("../images/asc-sort.svg") !important;
		}
	}


	.form-check-input {
		position: relative;
		@media (min-width: 813px) {
			margin-left: 0%;
		}

	}
	.wordwrap {
		white-space: pre-line !important;
		word-wrap: break-word;
	  }
	.rt-header-content {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
  }
button > i {
	margin-right: 5px;
}

.dropdown-toggle.btn-secondary {
	border: 1px solid $blue;
	background-color: transparent;
	color: $blue;
	&:hover,
	&:focus,
	&:active {
		color: $blue !important;
		background-color: transparent !important;
	}
}

.animal-map-popup {
	width: 250px;
    height: 350px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: white;
    border-radius: 5px;
	z-index: 3;
	padding: 10px;
	.close {
		position: abosulte;
		top: 5px;
		right: 5px;
	}
	ul {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
		width: 100%;
		li {
			button {
				width: 100%;
				margin-left: 0px;
			}
			&:not(:last-child){
				margin-bottom: 15px;
				width: 100%;
			}
		}
	}
	img {
		max-width: 50%;
	}
}


#track-filters{
	height: calc(100vh - 65px);
	position:fixed;
	right: -300px;
	top: 65px;
	width: 300px;
	background-color: white;
	transition: .5s linear right;
	z-index: 10;	
	.close {
		top: -3px;
		position: relative;
	}
	&.active {
		right: 0px;
	}
	.filters-footer {
		border-top: 1px solid $grey;
		//box-shadow: 0 -5px 5px -5px $grey;
		padding: 20px;
		width: 100%;
		height: 75px;
	}
	.filters-header {
		height: 50px;
		min-height: 50px;
		padding: 20px;
	}
	.filters-body {
		position: relative;
		padding: 20px;
		height: calc(100% - 125px);
		overflow: scroll;
		.form-group {
			margin-bottom: 0px;
		}
		.filter-block {
			padding-top: 15px;
			& p {
				font-weight: 600;
			}
		}
		.notifications {
			label {
				padding: 0;
			}
		}
		.time {
			input {
				margin-top: 0.5rem !important;
				margin-left: 0 !important;
			}
		}
	}
}
.p-30 {
	padding: 2%;
}

.ReactTable .rt-noData {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	position: relative;
	top: 21px;
}

.pad-15 {
  padding: 15px;
}
.priority-tag {
	line-height: 12px;
	font-size: 12px;
	padding: 5px 15px;
	border-radius: 5px;
	top: -3px;
	position: relative;
	display:inline-block;
	color: white;
  &.no-margin {
    top: 0px;
  }
}
.label-tag {
	line-height: 12px;
	font-size: 12px;
	padding: 5px 15px;
	border-radius: 5px;
	position: relative;
	display:inline-block;
	color: white;
	top: -3px;
  &.no-margin {
    top: 0px;
  }
}
.rule-box {
	padding: 20px 0px;
	float: left;
	&:not(:first-child) {
		margin-left: 1%;
	}
	&.w-20{
		width: 19%;
		&:nth-child(6n + 6) {
			margin-left: 0 !important;
			clear: both;
		}
	}
	&.w-40{
		width: 38%;
	}
	.rule-step-label {
		font-size: 16px;
		font-weight: 600;
		padding-bottom: 5px;
	}
	&:after {
		clear:both;
		content: "";
	}
}
.rule-definition {
	border: 1px solid $blue;
	background-color: rgba($blue, 0.2);
	width: 100%;
	height: 100px;
	font-size: 14px;
	padding: 20px;
	text-align: center;
	.bolded {
		text-transform: uppercase;
		color: $blue;
		font-size: 18px;
	}
	.center {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
	}
}
.bolded {
	font-weight: 600;
}
.not-action {
	cursor: pointer;
	font-size: 18px;
	&:not(:first-child) {
		margin-left: 10px;
	}
	&.snoozed {
		color: $red;
	}
	&.snoozable {
		color: $blue;
	}
	&.unsnoozable {
		color: $grey;
	}
	&.clear.true {
		color: $green;
	}
}
button ~ button {
	margin-right: 15px;
}
a ~ a{
	.label-tag {
		margin-left: 5px
	}
}
@function set-notification-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}

.outline {
	border: 1px solid black;
}
.leaflet-popup-content-wrapper{
	border-radius: 0px !important;
}
.simple-alerts {
	padding: 0;
	margin: 0;
	width: 100%;
	padding: 10px;
	list-style-type: none;
	li {
		padding-top: 7px;
		padding-left: 10px;
		padding-right: 10px;
		&:not(:last-child) {
			border-bottom: 1px solid $grey;
		}
	}
}
.capitalize {
	text-transform: capitalize;
}
.animal-card-table {
	td:first-child,
	td:first-child {
		width: 40%;
	}
}
.not-dd {
	width: 350px;
	ul {
		padding: 0;
		margin: 0;
		padding: 20px;
		list-style-type: none;
	}
	li {
		list-style-type: none;
		margin-bottom: 10px;
	}
	.notification-item {
		.priority {
			float: left;
			width: 20%;
			height: 55px;
			.circle {
				border-radius: 50%;
				width: 10px;
				height: 10px;
				margin: 0px auto;
				top: 50%;
				transform: translateY(-50%);
				position: relative;
			}
			&.low{
				.circle {
					background-color:  $green;
				}
			}
			&.medium {
				.circle {
					background-color: orange
				}
			}
			&.high {
				.circle{
					background-color: $red;
				}
			}
		}
		.text {
			height: 75px;
			width: 80%;
			float: left;
		}
	}
	.all-nots {
		text-align: center;
		cursor: pointer;
	}
}

.rule-snooze-tag {
	padding: 3px 6px 3px 6px;
	border-radius: 3px;
	height: 20px;
	line-height: 16px;
	font-size: 14px;
	color: white;
	background-color: $red;
	display: inline-block;
	margin-left: 5px;
}
i.rule-snoozed {
	color: $red;
}

.mapsites .leaflet-draw-edit-edit {
	display: none !important;
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?66360951');
  src: url('../fonts/fontello.eot?66360951#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?66360951') format('woff2'),
       url('../fonts/fontello.woff?66360951') format('woff'),
       url('../fonts/fontello.ttf?66360951') format('truetype'),
       url('../fonts/fontello.svg?66360951#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 [class^="stf-"]:before, [class*=" stf-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
   font-size: 11px;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
	top: -3px;
	position: relative;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.stf-labels:before { content: '\e800'; } /* '' */
.stf-livestock:before { content: '\e801'; } /* '' */
.stf-tags:before { content: '\e802'; } /* '' */
.stf-dashboard:before { content: '\e803'; } /* '' */
.stf-gateway:before {
	left: 2px;
	content: '\e804';
} /* '' */

.stf-geofence:before { content: '\e805'; } /* '' */
.stf-sites:before {
	left: -3px;
	content: '\e806';
} /* '' */
.stf-rules:before { content: '\e807'; } /* '' */
.stf-track:before { content: '\e808'; } /* '' */
.stf-notifications:before { content: '\e809'; } /* '' */
.stf-farms:before { content: '\e80a'; } /* '' */

.logo-place {
	background-image: url("../images/logo-diagri.svg");
	bottom: 50px;
	position: absolute;
	width: 75px;
	height: 28px;
	left: 40px;
	@media (max-width: 768px) {
		 position: relative;
		 bottom: 0px;
		 left: 0px;
	}
}
.btn {
	padding-top: 0.75em !important;
}

.auth-wrapper {
	background-size: cover;
}
.capitalise {
	text-transform: capitalize;
}
.details-title {
	font-size: 18px;
}

.details-subtitle {
	font-size: 14px;
	color: #9d9d9d !important;
}
.lds-roller {
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0px auto;
  top: 35vh;
  transform: translateY(-50%);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
