.multi-select .options{
    position: fixed  !important;
}
.tableLoading {
    font-size: 25px;
    text-align: center;
    padding-top: 20px;
}
.hide {
    display:none;
}