.subscription-table {
  .align-center {
    text-align: center;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .feature-row {
    .limited {
      color: grey;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .plan-header {
    .price {
      font-weight: bold;
      font-size: 24px;
      margin-top: 6px;
      line-height: 20px;
    }

    .title {
      padding: 4px;
    }

    .minor-text {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .plan-header:last-child {
    border-top-right-radius: 8px;
  }

  .plan-header:nth-child(2) {
    border-top-left-radius: 8px;
  }

  .grey {
    background-color: rgba(138, 138, 138, 0.5);
  }
  .blue {
    background-color: rgba(70, 104, 213, 0.5);
  }
  .green {
    background-color: rgba(28, 154, 34, 0.5);
  }
  .red {
    background-color: rgba(255, 0, 0, 0.5);
  }
  .feature-col {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .feature-row {
    border-bottom: 1px solid rgba(138, 138, 138, 0.5);
    height: 42px;
  }

  .feature-title {
    padding-top: 8px;
  }

  .selected-col {
    border-left: 2px solid black;
    border-right: 2px solid black;
  }
  .selected-plan-col {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 2px solid black;
  }
  .selected-btn-col {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
  }

  .btn-col {
    padding-top: 10px;
  }
}
