.react-tiny-popover-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .on-this-page-btn {
    width: 100%;
    margin-bottom: 5px;
  }

  .all-records-btn {
    width: 100%;
  }
}
