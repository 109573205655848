.rgwf .weather_animated {
  width: 100%;
  height: 300px;
  background: #ffffff;
  position: relative;
}

.rgwf .weather_animated .location {
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 15px;
  padding-top: 15px;
}
.rgwf .weather_animated .location span {
  font-weight: bold;
  word-break: break-word;
}

.rgwf .weather_animated .location .name {
  font-weight: normal;
  word-break: break-word;
}

.rgwf .weather_animated .main_content {
  height: auto;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rgwf .weather_animated .main_left {
  display: flex;
  text-align: center;
  flex-direction: column;
}
.rgwf .weather_animated .main_left .current_weather {
  font-weight: bold;
  display: block;
}

.rgwf .weather_animated .main_right {
  display: flex;
}
.rgwf .weather_animated .main_right .main_info {
  display: flex;
  flex-direction: column;
}
.rgwf .weather_animated .main_right span {
  display: block;
  margin-bottom: 2px;
  font-size: 13px;
}
.rgwf .weather_animated .main_right .temperature {
  font-size: 32px;
}

.rgwf .weather_animated .clear-fix {
  clear: both;
}

.rgwf .weather_animated .week {
  clear: both;
  display: block;
  width: 100%;
  text-align: center;
}
.rgwf .weather_animated .week .day {
  display: inline-block;
  width: 23%;
  height: 107px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 0.5%;
  padding-top: 10px;
}
.rgwf .weather_animated .week .day .name {
  font-weight: 600;
  font-size: 12px;
  display: block;
}
.rgwf .weather_animated .week .day .temperature {
  position: absolute;
  bottom: 11px;
  width: 100%;
  left: 2px;
  font-size: 14px;
  font-weight: 600;
}
