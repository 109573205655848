.error-403 {
  text-align: center;
  padding: 50px;
  .error-title {
    font-size: 36px;
    font-weight: bold;
    margin: 25px;
  }
  .error-code {
    font-size: 100px;
    font-weight: bold;
    line-height: 80px;
  }
  .error-btn {
    margin-top: 8px;
    .link-btn {
      color: white;
    }
  }
}
