.calendar-component {
  padding: 10px;
  .rbc-event.rbc-selected {
    background-color: #51ae32;
  }
  .rbc-toolbar {
    flex-wrap: wrap;
    .rbc-toolbar-item-2 {
      width: 100%;
    }
    .rbc-toolbar-label {
      font-size: 20px;
    }

    button:active:focus {
      color: #51ae32;
      background-color: #fff;
      border-color: #51ae32;
    }
    .rbc-btn-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      button {
        background-image: none;
        color: #fff;
        background-color: #51ae32;
        border-color: #51ae32;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
      }

      .rbc-active {
        border: 1px solid #51ae32;
        background-color: transparent;
        color: #51ae32;
      }
    }
  }
}
