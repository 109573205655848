.calendar-page {
  gap: 10px;

  .layout-col {
    padding: 5px;
  }
  .layout-template {
    width: 100%;
  }
  .calendar-block {
    padding: 0 20px 0 10px;
  }
}

@media (min-width: 1400px) {
  .calendar-page {
    display: flex;
    justify-content: space-between; 
    gap: 10px;
    
    .calendar-block {
      padding: 0 20px 0 10px;
    }
  }
}
